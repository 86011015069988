define("cheddargorge/routes/basket", ["exports", "@ember/routing/route", "rsvp", "@ember/service", "@ember/utils", "@ember/object"], function (_exports, _route, _rsvp, _service, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    session: (0, _service.inject)('session-user'),
    goods: (0, _service.inject)('goods'),
    headData: (0, _service.inject)(),
    async model() {
      let basket = (0, _object.get)(this, 'session.basket');
      basket = await this.store.findRecord('basket', basket.id, {
        reload: true
      });
      return basket;
    },
    async afterModel(basket) {
      (0, _object.set)(this, 'headData.title', 'View Basket | Cheddar Gorge');
      (0, _object.set)(this, 'headData.description', '');
      let basketItems = await basket.basketItems;
      let skus = await _rsvp.default.all(basketItems.mapBy('sku'));
      let products = await _rsvp.default.all(skus.filter(sku => (0, _utils.isPresent)(sku)).mapBy('product'));
      await _rsvp.default.all(products.mapBy('productFields'));
      await _rsvp.default.all(basket.basketItems.mapBy('promotion'));
    },
    resetController(controller) {
      (0, _object.set)(controller, 'promotionErrors', null);
    },
    actions: {
      saveBasketItem(basketItem) {
        const goods = this.goods;
        if (basketItem.quantity === 0) {
          return this._removeBasketItem((0, _object.get)(this, 'controller.basketItems'), basketItem);
        }
        return goods.saveBasketItem(basketItem);
      },
      removeBasketItem(basketItems, targetBasketItem) {
        this._removeBasketItem(basketItems, targetBasketItem);
      },
      async applyPromotion(basket, code) {
        const store = this.store;
        (0, _object.set)(this, 'controller.promotionErrors', []);
        let basketItem = store.createRecord('basket-item', {
          code: code,
          basket: basket
        });
        try {
          await basketItem.save();
        } catch (e) {
          this._handlePromotionErrors(basketItem, e);
        }
        basket = await basket.reload();
      },
      removePromotion(basketItem) {
        return basketItem.destroyRecord();
      },
      showNeedToKnow() {
        this.transitionTo('basket.need-to-know');
      }
    },
    _removeBasketItem(basketItems, targetBasketItem) {
      const goods = this.goods;
      let targetBasketItems = [];
      const bundleUUID = (0, _object.get)(targetBasketItem, 'metadata.bundleUUID');
      if ((0, _utils.isPresent)(bundleUUID)) {
        targetBasketItems = basketItems.filter(basketItem => {
          return (0, _object.get)(basketItem, 'metadata.bundleUUID') === bundleUUID;
        });
      } else {
        targetBasketItems = [targetBasketItem];
      }
      return goods.destroyBasketItems(basketItems, targetBasketItems);
    },
    _handlePromotionErrors(basketItem, e) {
      basketItem.destroyRecord();
      (0, _object.set)(this, 'controller.promotionErrors', e.errors);
    }
  });
  _exports.default = _default;
});